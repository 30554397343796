var $class="se2--banner-full-image-carousel",$name="BannerFullImageCarousel",$path="app/components/BannerFullImageCarousel/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';
import 'app/modules/accessibility';
import shell from 'app/modules/shell';

import 'app/partials/image';
import 'app/partials/productSelector';

import getHeaderHeightReduced from 'app/utilities/getHeaderHeightReduced';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import parseDecimal from 'app/utilities/parseDecimal';
import setTabIndex from 'app/utilities/setTabIndex';

import gsap from 'gsap';
import Draggable from 'gsap/Draggable';

import {
  navigationBreadcrumbsClassName,
} from 'app/components/NavigationBreadcrumbs/config';

import './partials/slide';

import forEach from 'lodash/forEach';
import map from 'lodash/map';

import {
  logoImageSelector,
  scrollDownButtonClassName,
  slideClassName,
  paginationBulletClassName,
} from './config';

const sliderWrapperClassName = 'slider-wrapper';
const smartBannerSelector = '.smartbanner';
const anchorsToDisableContainerElement = '.slide-content .buttons-wrapper';
const anchorElementClassName = 'se2--link-cta';
const buttonElementClassName = 'se2-button';

export default shell.registerComponent($this, ({
  addEventListener, configs, mount, settings, subscribeToMessage,
}) => {
  const initSlider = (element) => {
    const slideElements = element.querySelectorAll(`.${slideClassName}`);

    const slideDelay = 10;
    const slideDuration = 0.3;
    const bulletElements = element.querySelectorAll(`.${paginationBulletClassName}`);

    const prevButtonElement = element.querySelectorAll('#prevButton');
    const nextButtonElement = element.querySelectorAll('#nextButton');

    let activeSlide = 0;

    const detectSlideDirection = (slide, i) => (
      settings.direction === configs.directions.rtl
        ? -slide.getBoundingClientRect().width * i
        : slide.getBoundingClientRect().width * i
    );

    let positions = map(slideElements, detectSlideDirection);

    const selectSlide = (index) => {
      if (index === slideElements.length) {
        activeSlide = 0;
      } else if (index < 0) {
        activeSlide = slideElements.length - 1;
      } else {
        activeSlide = index;
      }

      gsap.to(slideElements, {
        x: -positions[activeSlide],
        duration: slideDuration,
      });

      forEach(slideElements, (slide, slideNumber) => {
        slide.classList.toggle(configs.css.classNames.active, slideNumber === activeSlide);
      });

      forEach(bulletElements,
        (bullet) => {
          setTabIndex(bullet, false);
          const bulletIndex = parseInt(bullet.getAttribute('data-index'), 10);
          const isActive = activeSlide === bulletIndex;
          bullet.classList.toggle(configs.css.classNames.active, isActive);
          bullet.setAttribute('aria-disabled', isActive);
        });

      forEach(slideElements, (slideElement, i) => {
        const tabElements = slideElement.querySelectorAll('a, button');
        const anchorElementContainerList = slideElement.querySelectorAll(anchorsToDisableContainerElement);

        forEach(tabElements, (el) => setTabIndex(el, activeSlide === i));

        const anchorElementContainers = slideElement.querySelectorAll(anchorsToDisableContainerElement);
        let ctaElementsToDisable = [];

        anchorElementContainers.forEach((container) => {
          const anchors = container.querySelectorAll(`.${anchorElementClassName}, .${buttonElementClassName}`);
          ctaElementsToDisable = [...ctaElementsToDisable, ...anchors];
        });

        // Fix for the case when there is only one slide,
        // and Skip to Main funcionality in app/components/HeaderSE/partials/skipNavigation.js
        // tries to select anchors/buttons that are hidden for mobile in CSS
        if ((anchorElementContainerList.length !== tabElements.length)
         && (slideElements.length === 1 && !isMobileBreakpoint())) {
          forEach(ctaElementsToDisable, (el) => el.setAttribute('disabled', 'disabled'));
        } else {
          forEach(tabElements, (el) => setTabIndex(el, activeSlide === i));
        }
      });
    };

    const timer = gsap.delayedCall(slideDelay, () => {
      selectSlide(activeSlide + 1);
      timer.restart(true);
    });

    const onAccessibilityMessage = () => {
      (settings.accessibility) ? timer.kill() : timer.restart(true);
    };

    const onModalMessage = ({ isOpened }) => (isOpened ? timer.kill() : timer.restart(true));

    subscribeToMessage(configs.messages.accessibility, onAccessibilityMessage);
    subscribeToMessage(configs.messages.modal, onModalMessage);

    forEach(bulletElements, (bullet) => addEventListener(bullet, 'click', () => {
      const bulletIndex = parseInt(bullet.getAttribute('data-index'), 10);
      timer.kill();
      selectSlide(bulletIndex);
      !settings.accessibility && timer.restart(true);
    }));

    forEach(prevButtonElement, (prevButton) => addEventListener(prevButton, 'click', () => {
      timer.kill();
      selectSlide(activeSlide - 1);
      !settings.accessibility && timer.restart(true);
    }));

    forEach(nextButtonElement, (nextButton) => addEventListener(nextButton, 'click', () => {
      timer.kill();
      selectSlide(activeSlide + 1);
      !settings.accessibility && timer.restart(true);
    }));

    addEventListener(element, 'focusin', () => {
      timer.kill();
    });

    addEventListener(element, 'focusout', () => {
      timer.restart(true);
    });

    selectSlide(0);

    const phantomElement = document.createElement('div');
    let dragStart = 0;

    const onDragStart = (event) => {
      dragStart = event.clientX;
      timer.kill();
    };

    const onDragEnd = (event) => {
      const offset = settings.direction === configs.directions.rtl
        ? event.clientX - dragStart
        : dragStart - event.clientX;
      selectSlide(offset < 0 ? activeSlide - 1 : activeSlide + 1);
      !settings.accessibility && timer.restart(true);
    };

    return {
      draggable: new Draggable(phantomElement, {
        type: 'x',
        // according to the https://www.researchgate.net average swipe distance on mobile is 18% from screen width
        minimumMovement: window.innerWidth * configs.gsapAnimation.recognitionRate,
        allowContextMenu: true,
        dragClickables: false,
        cursor: 'default',
        activeCursor: 'grabbing',
        trigger: element.querySelector(`.${sliderWrapperClassName}`),
        onDragStart,
        onDragEnd,
      }),
      onResize: () => {
        positions = map(slideElements, detectSlideDirection);

        gsap.set(slideElements, {
          x: -positions[activeSlide],
        });
      },
      timer,
      element,
    };
  };

  // For perf purposes temporary switched off
  // const launchContentAndControlsAnimations = (element) => {
  //   const animationContentElements = element.querySelectorAll(animationContentSelector);
  //   const controlsWrapperElements = element.querySelectorAll(controlsWrapperSelector);
  //   const revealTimeline = gsap.timeline({ scrollTrigger: {
  //     trigger: element,
  //     start: '+=50% bottom',
  //     end: 'bottom bottom',
  //   } });
  //
  //   revealTimeline.fromTo(animationContentElements,
  //     { opacity: 0, yPercent: 10 },
  //     { opacity: 1, duration: 1, yPercent: 0 }, 1);
  //   revealTimeline.fromTo(controlsWrapperElements,
  //     { opacity: 0, yPercent: 10, xPercent: -50 },
  //     { opacity: 1, duration: 1, yPercent: 0, xPercent: -50 }, 1);
  // };

  const scrollDownAnimation = (element) => {
    const scrollDownBtnElement = element.querySelector(`.${scrollDownButtonClassName}`);
    if (!scrollDownBtnElement) return;
    addEventListener(scrollDownBtnElement, 'click', () => {
      const { bottom } = element.getBoundingClientRect();
      const marginBlockEnd = parseDecimal(getComputedStyle(element).marginBlockEnd);
      const smartBannerHeight = document.querySelector(smartBannerSelector)?.offsetHeight ?? 0;
      let breadcrumbsHeight = parseInt(getComputedStyle(document.documentElement)
        .getPropertyValue('--breadcrumbs-height'), 10);
      breadcrumbsHeight = (!isMobileBreakpoint() && document.querySelector(`.${navigationBreadcrumbsClassName}`))
        ? breadcrumbsHeight
        : 0;
      gsap.to(window, {
        duration: 0.3,
        scrollTo: {
          y: (
            bottom + window.scrollY - getHeaderHeightReduced() - breadcrumbsHeight - smartBannerHeight + marginBlockEnd
          ),
        },
        ease: 'none',
      });
    });
  };

  const detectSlideLogo = (element) => {
    forEach(element.querySelectorAll(`.${slideClassName}`),
      (slide) => {
        if (/PowerMonitoring-IC-Logo|global100/i.test(slide.querySelector(logoImageSelector)?.src)) {
          slide.querySelector(logoImageSelector).classList.add('global100');
        }
      });
  };

  mount((element) => {
    detectSlideLogo(element);

    lazyHandleOpenModalButtons(addEventListener, element);

    scrollDownAnimation(element);
    const { onResize } = initSlider(element);
    const handleControlsPosition = () => {
      const pxRatio = settings.platform.system === configs.systems.macos
        ? window.screen.availWidth / document.documentElement.clientWidth
        : window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
      element.classList.toggle('zoom', pxRatio > 1.25);
    };

    const onWindowResize = () => {
      onResize();
      handleControlsPosition();
    };

    handleControlsPosition();

    addEventListener(window, 'resize', onWindowResize);
  });
});
